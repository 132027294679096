import axios from "axios"
import Interceptors from "./setupInterceptors"
import TeamsService from "./teams"
import UsersService from "./users"
import UsersLicenses from "./usersLicenses"

export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default {
  users: UsersService(httpClient),
  teams: TeamsService(httpClient),
  usersLicenses: UsersLicenses(httpClient),
  interceptors: Interceptors(httpClient)
}

<template>
  <ModalFactory />
  <Navbar />
  <RouterView />
  <Footer />
</template>

<script>
import ModalFactory from "@/components/ModalFactory";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    ModalFactory,
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
@import "./assets/styles/default.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>

export default (httpClient) => ({
  getTeams: async() => {
    const response = await httpClient.get("/Team");

    return {
      data: response.data
    }
  },
  addTeam: async(team) => {
    const response = await httpClient.post("/Team", team);

    return {
      data: response.data
    }
  },
  updateTeam: async(team) => {
    const response = await httpClient.put(`/Team/${team.id}`, team);

    return {
      data: response.data
    }
  },
  removeTeam: async(id) => {
    await httpClient.delete(`/Team/${id}`);
  }
})

export default (httpClient) => ({
  getUsers: async(url) => {
    const response = await httpClient.get(url);
    return {
      data: response.data
    }
  },
  updateLicenseStatus: async(user) => {
    const response = await httpClient.put(`/User/${user.id}`, user);

    return {
      data: response.data
    }
  },
  addUser: async(user) => {
    const response = await httpClient.post("/User", user);

    return {
      data: response.data
    }
  },
  removeUser: async(id) => {
    await httpClient.delete(`/User/${id}`);
  }
})

// Actions de Users
export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_DATA_USER = "UPDATE_DATA_USER";

// Actions de Teams
export const GET_TEAMS = "GET_TEAMS";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const CREATE_TEAM = "CREATE_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const UPDATE_DATA_TEAM = "UPDATE_DATA_TEAM";

// Actions de UserLicense
export const GET_USERS_LICENSES = "GET_USERS_LICENSES";
export const UPDATE_IN_USE_STATUS = "UPDATE_IN_USE_STATUS";
export const CREATE_USER_LICENSE = "CREATE_USER_LICENSE";
export const DELETE_USER_LICENSE = "DELETE_USER_LICENSE";
export const SEARCH_USER_LICENSES = "SEARCH_USER_LICENSE";
export const UPDATE_DATA_USER_LICENSE = "UPDATE_DATA_USER_LICENSE";

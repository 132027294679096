// Mutations de Users
export const ADD_USER = "ADD_USER"
export const SET_USER = "SET_USER"
export const CHANGE_USER_LICENSE_STATUS = "CHANGE_USER_LICENSE_STATUS"
export const REMOVE_USER = "REMOVE_USER"
export const CHANGE_USER = "CHANGE_USER"

// Teams
export const ADD_TEAM = "ADD_TEAM"
export const SET_TEAM = "SET_TEAM"
export const CHANGE_TEAM = "CHANGE_TEAM"
export const REMOVE_TEAM = "REMOVE_TEAM"
export const VALUE_TEAM = "VALUE_TEAM"
export const CHANGE_DATA_TEAM = "CHANGE_DATA_TEAM"

// UserLicense

export const ADD_USER_LICENSE = "ADD_USER_LICENSE"
export const SET_USER_LICENSE = "SET_USER_LICENSE"
export const CHANGE_IN_USE_STATUS = "CHANGE_IN_USE_STATUS"
export const REMOVE_USER_LICENSE = "REMOVE_USER_LICENSE"
export const UPDATE_USER_LICENSE = "UPDATE_USER_LICENSE"

// TokenModules
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN"

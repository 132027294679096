import services from "@/services";
import Swal from "sweetalert2"

import {
  CREATE_USER_LICENSE,
  DELETE_USER_LICENSE,
  GET_USERS_LICENSES,
  UPDATE_IN_USE_STATUS,
  UPDATE_DATA_USER_LICENSE,
  SEARCH_USER_LICENSES,
} from "@/store/type-actions";

import {
  ADD_USER_LICENSE,
  CHANGE_IN_USE_STATUS,
  REMOVE_USER_LICENSE,
  SET_USER_LICENSE,
  UPDATE_USER_LICENSE,
} from "@/store/type-mutations";

export const userLicense = {
  mutations: {
    [ADD_USER_LICENSE](state, user) {
      state.usersLicenses.push(user);
    },
    [SET_USER_LICENSE](state, users) {
      state.usersLicenses = users;
    },
    [CHANGE_IN_USE_STATUS](state, user) {
      const index = state.usersLicenses.findIndex((u) => u.id === user.id);
      state.usersLicenses[index] = user;
    },
    [REMOVE_USER_LICENSE](state, id) {
      state.usersLicenses = state.usersLicenses.filters((u) => u.id !== id);
    },
    [UPDATE_USER_LICENSE](state, user) {
      state.updateUserLicense = user;
    },
  },

  actions: {
    async [GET_USERS_LICENSES]({ commit }) {
      try {
        const { data } = await services.usersLicenses.getUsersLicenses();

        commit(SET_USER_LICENSE, data);
      } catch (error) {
        console.log(error);
      }
    },
    async [UPDATE_IN_USE_STATUS]({ commit }, user) {
      try {
        const { data } = await services.usersLicenses.updateUserLicense(user);

        commit(CHANGE_IN_USE_STATUS, data);
      } catch (error) {
        console.log(error);
      }
    },
    async [CREATE_USER_LICENSE]({ commit }, user) {
      try {
        const { data } = await services.usersLicenses.addUserLicense(user);
        commit(ADD_USER_LICENSE, data);
        return data;
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "warning",
          iconColor: "white",
          color: "white",
          background: "#593c95",
          title: `Falha!`,
          text: `${error.response.data}`,
          showConfirmButton: false,
          timer: 2500,
        });
        console.log(error);
      }
    },
    async [DELETE_USER_LICENSE]({ commit }, id) {
      try {
        await services.usersLicenses.removeUserLicense(id);

        commit(REMOVE_USER_LICENSE, id);
      } catch (error) {
        console.log(error);
      }
    },
    [UPDATE_DATA_USER_LICENSE]({ commit }, user) {
      commit(UPDATE_USER_LICENSE, user);
    },
    async [SEARCH_USER_LICENSES]({ commit }, props) {
      try {
        const { data } = await services.usersLicenses.findByDateAndStatus(
          props.finalDateStore,
          props.statusLicense
        );
        data.length > 0 && commit(SET_USER_LICENSE, data);
        return data.length > 0 ? true : false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};

import services from "@/services";
import Swal from "sweetalert2";

import { CREATE_TEAM, DELETE_TEAM, GET_TEAMS, UPDATE_DATA_TEAM, UPDATE_TEAM } from "@/store/type-actions";
import { ADD_TEAM, CHANGE_DATA_TEAM, CHANGE_TEAM, REMOVE_TEAM, SET_TEAM, VALUE_TEAM } from "@/store/type-mutations";

export const team = {
  mutations: {
    [ADD_TEAM](state, team){
      state.teams.push(team);
    },
    [SET_TEAM](state, teams){
      state.teams = teams
    },
    [CHANGE_TEAM](state, team){
      const index = state.teams.findIndex(t => t.id === team.id);
      state.teams[index] = team;
    },
    [CHANGE_DATA_TEAM](state, team){
      state.updateTeam = team;
    },
    [REMOVE_TEAM](state, id){
      state.teams = state.teams.filter(t => t.id !== id);
    }
  },
  actions: {
    async [GET_TEAMS]({commit}){
      try {
        const { data } = await services.teams.getTeams();

        commit(SET_TEAM, data);
      } catch (error) {
        console.log(error);
      }
    },
    async [CREATE_TEAM]({commit}, team){
      try {
        const { data } = await services.teams.addTeam(team);

        commit(ADD_TEAM, data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async [UPDATE_TEAM]({commit}, team){
      try {
        const { data } = await services.teams.updateTeam(team);

        commit(CHANGE_TEAM, data);
      } catch (error) {
        console.log(error);
      }
    },
    [UPDATE_DATA_TEAM]({commit}, team){
      commit(CHANGE_DATA_TEAM, team);
    },
    async [DELETE_TEAM]({commit}, id){
      try {
        await services.teams.removeTeam(id);

        commit(REMOVE_TEAM, id);
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          iconColor: "white",
          color: "white",
          background: "#593c95",
          title: `Falha!`,
          text: `${error.response.data}`,
          showConfirmButton: false,
          timer: 2500,
        });
        console.log(error, "🔥🔥🔥🔥🔥");
      }
    }
  },
  getters: {
    [VALUE_TEAM](state){
      return state.teams;
    }
  }
}

import services from "@/services"
import Keycloak from "keycloak-js"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store"

import "@fortawesome/fontawesome-free/css/all.css"

const initOptions = {
  url: process.env.VUE_APP_CONTAS_URL,
  realm: process.env.VUE_APP_CONTAS_REALM,
  clientId: process.env.VUE_APP_CONTAS_CLIENTID,
};

const keycloak = Keycloak(initOptions);

export const logout = () => {
  keycloak.logout();
  Response.Cookies.Clear();
};

keycloak
  .init({
    onLoad: "login-required",
    checkLoginIframe: false,
  })
  .then((auth) => {

    if (!auth) {
      window.location.reload();

    } else if (!keycloak.realmAccess.roles.includes(process.env.VUE_APP_PAPEIS_GESTAO)) {
      alert("Acesso não autorizado!");
    } else {

      const app = createApp(App);

      app.use(store).use(router).mount("#app");

      store.commit("atualizarToken", keycloak.token);
      store.commit("atualizarAccessToken", keycloak.token);
      store.commit("parseToken");

      services.interceptors.apiInterceptor();

      const href = location.href
      .replace(/[&?]code=[^&$]*/, "")
      .replace(/[&?]scope=[^&$]*/, "")
      .replace(/[&?]state=[^&$]*/, "")
      .replace(/[&?]session_state=[^&$]*/, "");

      const path = href.match(/[^#]*$/)[0];

      router.push(path);
    }
  })
  .catch((e) => {
    console.log(e);
  });

setInterval(() => {
  keycloak
    .updateToken(180)
    .then((refreshed) => {
      if (refreshed) {
        console.log("Token refreshed")
        store.commit("atualizarToken", keycloak.token);
        store.commit("atualizarAccessToken", keycloak.token);
        store.commit("parseToken");
      }
    })
    .catch(() => {
      console.error("Failed to refresh token");
    });
}, 10000);

import { store } from "@/store";
import { setGlobalLoading } from "@/store/modules/global";

export default (httpClient) => ({
  apiInterceptor: () => {
    httpClient.interceptors.request.use((config) => {
      setGlobalLoading(true);
      const token = store.getters.GET_ACCESS_TOKEN;

      if(token){
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
      (error) => {
        return Promise.reject(error);
    });

    httpClient.interceptors.response.use((response) => {
          setGlobalLoading(false);
          return response;
        }, async (error) => {
          console.log(error);
          setGlobalLoading(false);
          return Promise.reject(error);
        }
      )
  }
})

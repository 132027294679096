import { createRouter, createWebHashHistory } from "vue-router";

const ManagerView = () => import("@/views/ManagerView.vue");
const UserView = () => import("@/views/UserView.vue");
const Dashboard = () => import("@/views/Dashboard.vue");

const routes = [
  {
    path: "/",
    name: "gerenciais",
    component: ManagerView,
  },
  {
    path: "/colaboradores",
    name: "colaboradores",
    component: UserView
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;

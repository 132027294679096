import { createStore, useStore as vuexUseStore } from 'vuex';

import { user } from "./modules/user";
import { team } from "./modules/team";
import  global from "./modules/global";
import { userLicense } from "./modules/userLicense";
import tokenModules from "./modules/tokenModules";

export const store = createStore({
  state: {
    user: {
     users: [],
     updateUser: {}
    },
    team: {
      teams: [],
      updateTeam: {}
    },
    userLicense: {
      usersLicenses: [],
      updateUserLicense:{}
    },
  },
  modules: {
    user,
    team,
    userLicense,
    tokenModules,
    global
  }
});

export default function useStore() {
  return vuexUseStore();
}

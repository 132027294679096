export default (httpClient) => ({
  getUsersLicenses: async () => {
    const response = await httpClient.get("/UserLicense");

    return {
      data: response.data || [],
    };
  },
  updateUserLicense: async (user) => {
    const response = await httpClient.put(`/UserLicense/${user.id}`, user);

    return {
      data: response.data,
    };
  },
  addUserLicense: async (user) => {
    const response = await httpClient.post("/UserLicense", user);

    return {
      data: response.data,
    };
  },
  removeUserLicense: async (id) => {
    await httpClient.delete(`/UserLicense/${id}`);
  },
  findByDateAndStatus: async (date, status) => {
    const response = await httpClient.get(
      `/endDateAndStatus?period=${date}&status=${status}`
    );
    return {
      data: response.data,
    };
  },
});

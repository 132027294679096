<template>
<div class="theFooter">
<a href="https://www.alterdata.com.br/">
  <img src="@/assets/img/logoAlterdataFooter.png" alt="logo Alterdata">
</a>
</div>
</template>

<script>
export default {

}
</script>
<style scoped>
.theFooter {
    background-color: var(--c-default);
    width: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    height: 60px;
    top: 95vh;
    padding: 0;
}
a{
    zoom: 0.25;
    position: absolute;
    margin-top: 5vh;
}



</style>

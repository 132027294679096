import services from "@/services";
import Swal from "sweetalert2";

import { CREATE_USER, DELETE_USER, GET_USERS, UPDATE_DATA_USER, UPDATE_USER } from "@/store/type-actions";
import { ADD_USER, CHANGE_USER, CHANGE_USER_LICENSE_STATUS, REMOVE_USER, SET_USER } from "@/store/type-mutations";

export const user = {
  mutations: {
    [ADD_USER](state, user){
      state.users.push(user)
    },
    [SET_USER](state, users){
      state.users = users
    },
    [CHANGE_USER_LICENSE_STATUS](state, user){
      const index = state.users.findIndex(u => u.id === user.id);
      state.users[index] = user;
    },
    [REMOVE_USER](state, id){
      state.users = state.users.filters(u => u.id !== id);
    },
    [CHANGE_USER](state, user){
      state.updateUser = user;
    }
  },
  actions: {
    async [GET_USERS]({commit}, teamId){
      try {
        let url = "/User"
        if(teamId){
          url = `/get-user-idteam?team_id=${teamId}`
        }
        const { data } = await services.users.getUsers(url);

        commit(SET_USER, data);
      } catch (error) {
        console.log(error)
      }
    },
    async [UPDATE_USER]({commit}, user){
      try {
        const { data } = await services.users.updateLicenseStatus(user);

        commit(CHANGE_USER_LICENSE_STATUS, data);
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "warning",
          iconColor: "white",
          color: "white",
          background: "#593c95",
          title: `Falha!`,
          text: `${error.response.data}`,
          showConfirmButton: false,
          timer: 2500,
        });
        console.log(error);
      }
    },
    async [CREATE_USER]({commit}, user){
      try {
        const {data} = await services.users.addUser(user);
        commit(ADD_USER, data);
        return data;
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "warning",
          iconColor: "white",
          color: "white",
          background: "#593c95",
          title: `Falha!`,
          text: `${error.response.data}`,
          showConfirmButton: false,
          timer: 2500,
        });
        console.log(error);
      }
    },
    async [DELETE_USER]({commit}, id){
      try {
        await services.users.removeUser(id);

        commit(REMOVE_USER, id);
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          iconColor: "white",
          color: "white",
          background: "#593c95",
          title: `Falha!`,
          text: `${error.response.data}`,
          showConfirmButton: false,
          timer: 2500,
        });
        console.log(error);
      }
    },
    [UPDATE_DATA_USER]({commit}, user){
      commit(CHANGE_USER, user);
    }

  }
}



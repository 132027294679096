<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="true" data-target="navbarAlterUp"
        id="burger" @click="toggleMenu()">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <img src="../assets/img/logoAlterUp.png" />
    </div>

    <div id="navbarAlterUp" class="navbar-menu">
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Menu </a>

          <div class="navbar-dropdown">
            <RouterLink to="/">
              <a class="navbar-item" @click="toggleMenu()"> Licenças </a>
            </RouterLink>

            <RouterLink to="/colaboradores">
              <a class="navbar-item" value="/colaboradores" @click="toggleMenu()">
                Colaboradores
              </a>
            </RouterLink>
          </div>
        </div>
        <RouterLink to="/dashboard">
          <a class="navbar-item" @click="toggleMenu()">Dashboard</a>
        </RouterLink>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="navbar-item" :href="urlLogout" @click="logoutContas" title="Sair">
              <i class="fa-solid fa-arrow-right-from-bracket"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logout } from "@/main"

export default {
  data() {
    return {
      urlLogout: "https://contas.alterdata.com.br/auth/realms/alterdata/protocol/openid-connect/logout?redirect_uri=" + window.location
    }

  },
  methods: {
    toggleMenu() {
      let dropMenu = document.getElementById("navbarAlterUp")
      dropMenu.classList.toggle("is-active")
    },
    logoutContas() {
      logout()
    }
  },
}
</script>

<style scoped>
.navbar {
  background-color: var(--c-default);
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 90px;
  margin-bottom: 2vh;
}

.navbar-burger {
  color: var(--c-white-default);
  align-self: center;
}

.navbar-dropdown {
  color: var(--c-white-default);
  background-color: var(--c-default);
}

.navbar-link[data-v-41458b80]:not(.is-arrowless) {
  color: var(--c-white-default);
  background-color: var(--c-default);
}

.navbar-item[data-v-41458b80] {
  color: var(--c-white-default);
  background-color: var(--c-default);
}

.navbar-link:not(.is-arrowless)::after {
  border-color: var(--c-white-default);
}

.navbar-menu {
  color: var(--c-white-default);
  background-color: var(--c-default);
  display: flexbox;
}

.navbar-link {
  color: var(--c-white-default);
  background-color: var(--c-default);
}

.navbar-link:hover {
  color: var(--c-white-default);
  background-color: var(--c-default);
}

.navbar-item {
  color: var(--c-white-default);
}

.navbar-link:not(.is-arrowless) {
  color: white;
}

@media screen and (max-height: 800px) and (max-width: 1022px) {
  .navbar-menu[data-v-41458b80] {
    margin-top: 40vh;
    position: absolute
  }
}

i {
  color: var(--c-white-default);
  float: right;
  cursor: pointer;
}

select:hover {
  cursor: pointer;
}

.links {
  margin-left: 10vh;
  display: flex;
  flex-direction: row;
}

.txt {
  color: var(--c-white-default);
  font-size: 20px;
  margin-left: 3vh;
}

img {
  width: 160px;
  height: 80px;
}

select {
  background-color: var(--c-default);
  color: var(--c-white-default);
  border: var(--c-default);
  font-size: 20px;
  height: 80px;
  margin-left: 3vh;
  outline: none;
}

span2 {
  margin-right: 2vh;
}
</style>

